class PlannerController {
	constructor() {
		this.initHandlebarFormatDate();
		this.themes = [];
		this.page = 1;
		this.itemsPerPage = 12;
		this.pagePadding = 3;
		let curDate = new Date();
		this.startDate = new Date(curDate);
		this.endDate = new Date(curDate.setFullYear(curDate.getFullYear() + 1));
		this.filterContainer = document.querySelector('.themeFilter .themes');
		this.dateContainer = document.querySelector('.dateFilter');
		this.listContainer = document.querySelector('#listview .listBlockView');
		this.pagerContainer = document.querySelector('.pagerContainer .pager .paginationList');
		this.eventAPIURL = "/umbraco/api/planner/getevents";
		this.locationAPIURL = "/umbraco/api/planner/getlocations";
		this.routeAPIURL = "/umbraco/api/planner/getroutes";
		this.initFilter();
		this.initDateChanged();
		this.initDatePicker();
		this.initPagingEvents();
	}

	initFilter() {
		this.filterContainer.querySelectorAll('ul li input[type="checkbox"]').forEach(input => {
			if (input.checked) {
				this.themes.push(input.value);
			}
		});
		this.initFilterEvents();
	}

	initFilterEvents() {
		this.filterContainer.querySelectorAll('ul li input[type="checkbox"]').forEach(input => {
			input.addEventListener('change', (event) => {
				event.preventDefault();
				if (input.checked) {
					this.themes.push(input.value);
				} else {
					this.themes.remove(input.value);
				}
				this.currentPage = 1;
				this.refreshContent();
			});
		});
	}

	initHandlebarFormatDate() {
		Handlebars.registerHelper("formatDate", function (datetime) {
			console.log(datetime);
			if (datetime != '') {
				var thisDate = new Date(datetime);
				var options = { year: 'numeric', month: 'long', day: '2-digit' };
				return thisDate.toLocaleDateString("nl-NL", options);
			}
			else
				return datetime;
		}
		);
	}

	initDateChanged() {
		if (this.dateContainer !== null) {
			this.clearButton = this.dateContainer.querySelector('label#clear');
			this.dateContainer.querySelectorAll('ul li input[type="radio"]').forEach(input => {
				input.addEventListener('change', (event) => {
					var update = false;
					let today = new Date();
					switch (input.value) {
						case "today":
							this.startDate = today;
							this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
							this.clearButton.classList.add("show");
							update = true;
							break;
						case "tomorrow":
							let tomorrow = new Date(today.setDate(today.getDate() + 1));
							this.startDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0, 0);
							this.endDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 23, 59, 59);
							this.clearButton.classList.add("show");
							update = true;
							break;
						case "week":
							this.startDate = new Date(today);
							var sundayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
							this.endDate = new Date(sundayOfWeek.getFullYear(), sundayOfWeek.getMonth(), sundayOfWeek.getDate(), 23, 59, 59);
							this.clearButton.classList.add("show");
							update = true;
							break;
					}

					if (update) {
						this.currentPage = 1;
						this.refreshContent();
					}
					// If not these, radio changed to datepicker. different method.
				});
			});

			this.clearButton.addEventListener('click', event => {
				this.clearButton.classList.remove("show");
				this.dateContainer.querySelectorAll('ul li input[type="radio"]').forEach(radio => {
					radio.checked = false;
					if (radio.closest('label').id === "customChoice") {
						radio.closest('label').querySelector('.labelTitle').innerHTML = 'Kies een datum';
					}
				});
				this.currentPage = 1;
				let today = new Date();
				this.startdate = new Date(today);
				this.endDate = new Date(today.setFullYear(today.getFullYear() + 1));
				this.refreshContent();
			});
		}
	}

	initPagingEvents() {
		if (this.pagerContainer !== null) {
			this.pagerContainer.querySelectorAll('a').forEach(pagerItem => {
				pagerItem.addEventListener('click', event => {
					event.preventDefault();
					if (!pagerItem.classList.contains("disabled")) {
						window.scroll({
							top: (this.listContainer.getBoundingClientRect().top + window.pageYOffset) - 300,
							left: 0,
							behavior: 'smooth'
						});
						this.currentPage = pagerItem.dataset.pageid;
						this.refreshContent();
					}
				});
			});
		}
	}


	refreshContent() {
		var apiURL = "";
		console.log(calendarPageType);
		switch (calendarPageType) {
			case "events":
				apiURL = this.eventAPIURL;
				break;
			case "locations":
				apiURL = this.locationAPIURL;
				break;
			case "routes":
				apiURL = this.routeAPIURL;
				break;
		}



		$.ajax({
			url: apiURL,
			method: 'get',
			dataType: 'json',
			data: {
				themes: this.themes,
				startDate: this.startDate.toISOString(),
				endDate: this.endDate.toISOString(),
				page: this.currentPage,
				itemsPerPage: this.itemsPerPage
			},
			success: data => {
				var listItems = [];
				this.listContainer.innerHTML = '';
				if (data.Events !== undefined) {
					data.Events.forEach(item => {

						let itemElement = this.createCalendarItem(item);
						listItems.push(itemElement);
					});

					listItems.forEach(calItem => {
						this.listContainer.appendChild(calItem);
					});
					this.refreshPager(data.Page, data.TotalItems, data.Pages);
				}


			}
		});
	}

	refreshPager(currentPage, totalItems, pages) {
		if (this.pagerContainer !== null) {
			this.pagerContainer.innerHTML = '';
			if (pages > 1) {
				var nextPages = [];
				var prevPages = [];


				var curPage = currentPage;
				var i = 0;
				while (curPage < pages && i < this.pagePadding * 2) {
					curPage++;
					i++;
					nextPages.push(curPage);
				}
				curPage = currentPage;
				i = 0;
				while (curPage > 1 && i < this.pagePadding * 2) {
					curPage--;
					i++;
					prevPages.push(curPage);
				}

				if (prevPages.length > this.pagePadding && nextPages.length > this.pagePadding) {
					prevPages = prevPages.slice(0, this.pagePadding);
					nextPages = nextPages.slice(0, this.pagePadding);
				}
				else if (prevPages.length > this.pagePadding && nextPages.length <= this.pagePadding) {
					prevPages = prevPages.slice(0, ((this.pagePadding * 2) - nextPages.length));
				}
				else if (prevPages.length <= this.pagePadding && nextPages.length > this.pagePadding) {
					nextPages = nextPages.slice(0, ((this.pagePadding * 2) - prevPages.length));
				}
				prevPages.reverse();

				var pagerItems = [];
				pagerItems.push(this.createPagerItem({
					pageId: currentPage - 1,
					label: "<",
					classString: "first",
					linkClass: ((currentPage > 1) ? "" : "disabled")
				}));

				prevPages.forEach(prevPage => {
					pagerItems.push(this.createPagerItem({
						pageId: prevPage,
						label: prevPage
					}));
				});
				pagerItems.push(this.createPagerItem({
					pageId: currentPage,
					label: currentPage,
					classString: 'active'
				}));
				nextPages.forEach(nextPage => {
					pagerItems.push(this.createPagerItem({
						pageId: nextPage,
						label: nextPage
					}));
				});
				pagerItems.push(this.createPagerItem({
					pageId: currentPage + 1,
					label: ">",
					linkClass: ((currentPage < pages) ? "" : "disabled")
				}));

				pagerItems.forEach(pagerItem => {
					this.pagerContainer.appendChild(pagerItem);
				});

				this.initPagingEvents();
			}
		}
	}

	createCalendarItem(data) {
		var tempDiv = document.createElement('div');
		tempDiv.innerHTML = Handlebars.templates.calendarItem(data);
		return tempDiv.firstElementChild;
	}

	createPagerItem(data) {
		var tempDiv = document.createElement('div');
		tempDiv.innerHTML = Handlebars.templates.pagerItem(data);
		return tempDiv.firstElementChild;
	}


	initDatePicker() {
		var thisController = this;

		$('#customChoice').on('click', function () {
			console.log("clicked");
			$('#customDate').focus();
		});

		$('#customDate').datepicker({
			language: 'nl',
			minDate: new Date(),
			autoClose: true,
			dateFormat: 'yyyy-mm-dd',
			onSelect: function (fd, d, picker) {
				// Do nothing if selection was cleared
				if (!d) return;

				var options = { year: 'numeric', month: '2-digit', day: '2-digit' };

				$('.labelTitle.custom').html(d.toLocaleDateString("nl-NL", options));

				thisController.startDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
				thisController.endDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
				thisController.clearButton.classList.add("show");
				thisController.currentPage = 1;
				thisController.refreshContent();
			}
		});
	}
}