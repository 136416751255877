class MapController {
	constructor() {
		if (document.querySelector('#map') !== null) {
			this.apiKey = 'AIzaSyBtNRJu_ACU2SEeu9r_gCx4T16lfqT4_Wo';
			this.mapElement = document.querySelector('#map');
			this.filterContainer = document.querySelector('.themeFilter .themes');

			this.map = null;

			this.markers = [];
			this.themes = [];
			this.version = 'quarterly'; // Use either quarterly or weekly
			this.language = 'nl';

			this.options = {
				zoom: 11,
				maxZoom: 15,
				minZoom: 8,
				mapTypeId: 'roadmap',
				clickableIcons: true,
				disableDoubleClickZoom: false,
				draggable: true,
				keyboardShortcuts: true,
				scrollWheel: true,
				fullscreenControl: false,
				mapTypeControl: false,
				rotateControl: false,
				scaleControl: false,
				streetViewControl: false,
				zoomControl: true,
				styles: [
					{ "featureType": "road.highway", "elementType": "labels", "stylers": [{ "visibility": "off" }] },
					{ "featureType": "road.arterial", "elementType": "labels", "stylers": [{ "visibility": "off" }] }
				]
			};

			this.init();
			this.initFilter();
			this.initFilterEvents();
		}
	}

	init() {
		let GoogleApi = document.createElement('script');
		GoogleApi.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&v=${this.version}&language=${this.language}&region=${this.language}&libraries=places,geometry&callback=app.mapController.createMap`;
		document.body.appendChild(GoogleApi);
	}

	initFilter() {
		if (this.filterContainer != null) {
			this.filterContainer.querySelectorAll('ul li input[type="checkbox"]').forEach(input => {
				if (input.checked) {
					this.themes.push(input.value);
				}
			});
			this.initFilterEvents();
		}
	}

	initFilterEvents() {
		this.filterContainer.querySelectorAll('ul li input[type="checkbox"]').forEach(input => {
			input.addEventListener('change', (event) => {
				event.preventDefault();
				if (input.checked) {
					this.themes.push(input.value);
				} else {
					this.themes.remove(input.value);
				}
				this.refreshMap();
			});
		});
	}

	refreshMap() {
		this.markers.forEach(marker => {
			const found = marker.themes.some(r => this.themes.indexOf(r) >= 0);
			if (found) {
				marker.setVisible(true);
			}
			else {
				marker.setVisible(false);
			}
		});
	}

	createMap() {
		this.options.center = new google.maps.LatLng(52.906792, 6.636842);
		this.bounds = new google.maps.LatLngBounds();
		this.map = new google.maps.Map(this.mapElement, this.options);

		this.addObjectsToMap();
	}

	addObjectsToMap() {
		if (mapItems !== null && mapItems.length > 0) {
			for (var i = 0; i < mapItems.length; i++) {
				var obj = mapItems[i];
				this.addMarker(obj);
			}
		}
	}

	createInfoWindow(mapItemObj) {
		console.log(mapItemObj);
		var infoWindow = document.querySelector('#mapContentItem');
		var title = infoWindow.querySelector('.title');
		title.innerHTML = mapItemObj.Title;

		var address = infoWindow.querySelector('.address');
		address.innerHTML = `${mapItemObj.Street}
<br>
${mapItemObj.Location}
<br>
${mapItemObj.PhoneNumber}`;

		var image = infoWindow.querySelector('.image');
		image.attributes.getNamedItem('src').value = mapItemObj.ImageUrl;
		image.attributes.getNamedItem('alt').value = mapItemObj.ImageAlt;

		var link = infoWindow.querySelector('.link');
		link.attributes.getNamedItem('href').value = mapItemObj.Url;

		var icon = infoWindow.querySelector('.icon');
		icon.attributes.getNamedItem('src').value = this.getTypeIcon(mapItemObj.Themes[0]);
		icon.attributes.getNamedItem('alt').value = mapItemObj.Themes[0];

		infoWindow.classList.add('show');

		var closeButton = infoWindow.querySelector('a.close');

		closeButton.addEventListener('click', function (e) {
			e.preventDefault();
			infoWindow.classList.remove('show');
		});
	}

	addMarker(mapItemObj) {

		var theme = '';
		if (mapItemObj.Themes[0] != null) {
			theme = mapItemObj.Themes[0].replace(' ', '');
		}
		//console.log(theme, mapItemObj);

		const markerOptions = {
			map: this.map,
			position: {
				lat: Number(mapItemObj.Lat),
				lng: Number(mapItemObj.Long)
			},
			icon: {
				url: this.getTypeIcon(theme),
				anchor: new google.maps.Point(39, 60),
				size: new google.maps.Size(40, 40),
				scaledSize: new google.maps.Size(40, 40)
			},
			themes: mapItemObj.Themes
		};

		const marker = new google.maps.Marker(markerOptions);
		

		marker.addListener('click', function () {
			app.mapController.createInfoWindow(mapItemObj);
		});

		this.bounds.extend(marker.position);

		this.markers.push(marker);

		if (this.boundTimeout !== null) {
			clearTimeout(this.boundTimeout);
		}

		this.boundTimeout = setTimeout(() => {
			this.map.fitBounds(this.bounds);
			this.boundTimeout = null;
		}, 100);
	}

	getTypeIcon(type) {
		var iconUrl = "/img/icons/map/prehistorie.svg";
		switch (type) {
			case "prehistorie":
				iconUrl = "/img/icons/map/prehistorie.svg";
				break;
			case "boermarke":
				iconUrl = "/img/icons/map/boermarke.svg";
				break;
			case "moderndrenthe":
				iconUrl = "/img/icons/map/modern drenthe.svg";
				break;
			case "nieuwewerelden":
				iconUrl = "/img/icons/map/nieuwe werelden.svg";
				break;
			case "beschavingsoffensief":
				iconUrl = "/img/icons/map/beschavingsoffensief.svg";
				break;
			case "randvandrenthe":
				iconUrl = "/img/icons/map/rand van drenthe.svg";
				break;
		}
		return iconUrl;
	}
}