class ReadMoreController {
	constructor() {
		document.querySelectorAll('.readMore').forEach(container => {
			var content = container.querySelector('.content');
			var readmoreButton = container.querySelector('.readMoreBtn');
			if (content !== null && readmoreButton !== null) {
				if (content.scrollHeight > container.dataset.cutoffheight) {
					content.style.maxHeight = container.dataset.cutoffheight + 'px';
					content.classList.add('animate');
					container.classList.add('showMore');
				}

				readmoreButton.addEventListener('click', (event) => {
					event.preventDefault();
					this.showMore(event);
				});
			}
		});
	}

	showMore(btn) {
		var thisContainer = btn.target.closest('.readMore');
		var thisContent = thisContainer.querySelector('.content');
		console.log(thisContent.scrollHeight);
		thisContent.style.maxHeight = thisContent.scrollHeight +'px';
		thisContainer.classList.remove('showMore');
	}
}