window.addEventListener('load', () => {
	const app = new App();
	app.resizeThrottle = 50;
	app.scrollThrottle = 100;

	app.addController('headerController', new HeaderController(false));
	app.headerController.init();
	app.addController('avgController', new AVGController());
	app.addController('mediaController', new MediaController());
	app.addController('carouselController', new CarouselController());
	app.addController('mapController', new MapController());
	app.addController('calendarController', new CalendarViewController());
	app.addController('variousController', new VariousController());
	app.addController('readMoreController', new ReadMoreController());

	bodyClasses = document.body.classList;

	if (bodyClasses.contains('storiesOverviewPage')) {
		var listPagerController = new ListPagerController('#listview .listBlockView', '.block:not(.hide)', 12, '.pager ul li a');
		app.addController('pagerController', listPagerController);		
		app.addController('themeFilterController', new ThemeFilterController(listPagerController));		
	}

	if (bodyClasses.contains('eventOverviewPage') || bodyClasses.contains("locationOverviewPage") || bodyClasses.contains("routeOverviewPage")) {
		app.addController('plannerController', new PlannerController());
	}

	if (bodyClasses.contains("timeLineOverviewPage")) {
		app.addController('timelineScrollController', new TimelineScrollController());
	}

	// Add the app instance to the window object so it can be reached from anywhere
	window.app = app;
});