class HeaderController {
	constructor(autoinit = true) {
		this.previousScroll = 0;
		this.threshold = 20;
		this.headerElement = null;
		this.notTop = false;

		if (autoinit) {
			this.init();
		}
	}

	init() {
		this.headerElement = document.querySelector('header');
		this.contentElement = document.querySelector('section.content');
		this.previousScroll = window.pageYOffset;
		this.notTop = window.pageYOffset > 1;

		this.mobileMenuButton = this.headerElement.querySelector('a.menu');
		if (this.mobileMenuButton !== null) {
			this.mobileMenuButton.addEventListener('click', this.toggleMobileMenu.bind(this));
		}
		this.mobileMenuSearchButton = this.headerElement.querySelector('a.search');
		if (this.mobileMenuSearchButton !== null) {
			this.mobileMenuSearchButton.addEventListener('click', event => {
				this.toggleMobileMenu();
				this.headerElement.querySelector('div.menuItems div.search input').focus();
			});
		}

		this.headerElement.querySelector('div.selectedLanguage').addEventListener('click', () => {
			this.headerElement.querySelector('div.languageSelection').classList.toggle('active');
		});

		this.headerElement.querySelector('nav').classList.add('animations');

		// Register events
		this.app.registerResizeEvent(this.resize.bind(this));
		this.app.registerScrollEvent(this.scroll.bind(this));
	}

	resize() {
		this.previousScroll = window.pageYOffset;
		this.threshold = this.headerElement.clientHeight;

		this.scroll();
	}

	scroll() {
		if (window.pageYOffset > this.threshold) {
			if (this.previousScroll !== window.pageYOffset) {
				if (this.previousScroll < window.pageYOffset) {
					this.headerElement.classList.add('scrolled');
				} else {
					this.headerElement.classList.remove('scrolled');
				}
			}
		}
		this.previousScroll = window.pageYOffset;
	}

	toggleMobileMenu() {
		this.mobileMenuActive = !this.mobileMenuActive;

		if (this.mobileMenuActive) {
			this.headerElement.classList.add('mobileMenuActive');
			this.mobileMenuButton.classList.add('active');
			this.mobileMenuButton.classList.add('delay');
		} else {
			this.headerElement.classList.remove('mobileMenuActive');
			this.mobileMenuButton.classList.remove('active');
			setTimeout(() => {
				this.mobileMenuButton.classList.remove('delay');
			}, 500);
		}
	}
}