(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['calendarItem'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<span class=\"date\">"
    + ((stack1 = (helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.StartDate : depth0),{"name":"formatDate","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "				<svg class=\"logo "
    + ((stack1 = alias1(depth0, depth0)) != null ? stack1 : "")
    + "\">\r\n					<use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#logo_"
    + ((stack1 = alias1(depth0, depth0)) != null ? stack1 : "")
    + "\"></use>\r\n				</svg>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "﻿<li>\r\n	<a href=\""
    + ((stack1 = ((helper = (helper = helpers.Url || (depth0 != null ? depth0.Url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Url","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.StartDate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		<img src=\""
    + ((stack1 = ((helper = (helper = helpers.ImageUrl || (depth0 != null ? depth0.ImageUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ImageUrl","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" />\r\n		<div class=\"listItemContent\">\r\n			<div class=\"icons\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.Themes : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\r\n			<span class=\"title\">"
    + ((stack1 = ((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n			<p>\r\n				"
    + ((stack1 = ((helper = (helper = helpers.Intro || (depth0 != null ? depth0.Intro : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Intro","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\r\n			</p>\r\n		</div>\r\n	</a>\r\n</li>";
},"useData":true});
templates['pagerItem'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "﻿<li class=\""
    + ((stack1 = ((helper = (helper = helpers.classString || (depth0 != null ? depth0.classString : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classString","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\">\r\n	<a data-pageid=\""
    + ((stack1 = ((helper = (helper = helpers.pageId || (depth0 != null ? depth0.pageId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageId","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" href=\"?page="
    + ((stack1 = ((helper = (helper = helpers.pageId || (depth0 != null ? depth0.pageId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageId","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\""
    + ((stack1 = ((helper = (helper = helpers.linkClass || (depth0 != null ? depth0.linkClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkClass","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\">\r\n		"
    + ((stack1 = ((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\r\n	</a>\r\n</li>";
},"useData":true});
})();