class VariousController {
	constructor() {
		this.initHomepageScroll();
		this.initSearch();
	}

	initHomepageScroll() {
		var discoverSection = document.querySelector(".discoverIcons");

		if (discoverSection !== null) {
			var scrollLink = document.querySelector('svg.scrollLink');
			if (scrollLink !== null) {
				scrollLink.addEventListener('click', (event) => {
					window.scroll({
						top: (discoverSection.getBoundingClientRect().top + window.pageYOffset) - 300,
						left: 0,
						behavior: 'smooth'
					});
				});
			}
		}
	}

	initSearch() {
		document.querySelectorAll('div.search svg.icon_search').forEach(searchIcon => {
			searchIcon.addEventListener('click', event => {
				searchIcon.closest('form').submit();
			});
		});
	}
}