class ListPagerController {
	constructor(overviewSelector = '', itemSelector = '', itemPerPageCount = 10, pagerCallSelector = null) {
		this.listitems = [];
		this.currentPage = 0;
		this.itemPerPageCount = itemPerPageCount;
		this.pageCallSelector = pagerCallSelector;
		this.pagerCallItem = document.querySelector(this.pageCallSelector);
		this.overviewItem = document.querySelector(overviewSelector);
		this.itemSelector = itemSelector;
		this.pages = [];

		this.initPager();
	}

	resetPages() {
		this.currentPage = 0;
		var pages = document.querySelectorAll('.itemPage');

		if (pages.length > 0) {
			pages.forEach(page => {
				var contents = Array.from(page.children);

				contents.forEach(elem => {
					this.overviewItem.insertBefore(elem, page);
				});

				page.remove();
			});
		}
	}

	initPager() {
		this.resetPages();
		this.listitems = [];
		this.pages = [];

		if (this.overviewSelector !== '' && this.itemSelector !== '') {

			if (this.overviewItem !== null) {
				this.overviewItem.querySelectorAll(this.itemSelector).forEach(item => {
					this.listitems.push(item);
				});

				if (this.listitems.length > this.itemPerPageCount) {
					this.createPages();
				}
				else {
					var pager = document.querySelector("#pager");
					pager.innerHTML = '';
				}
			}
		}
	}

	createPages() {
		var i = 0;
		var j = 0;
		var temparray;
		for (i = 0, j = this.listitems.length; i < j; i += this.itemPerPageCount) {
			temparray = this.listitems.slice(i, i + this.itemPerPageCount);

			var el = temparray[0];

			var wrapper = document.createElement('div');
			wrapper.classList.add('itemPage');

			this.overviewItem.insertBefore(wrapper, el);

			temparray.forEach(item => {
				wrapper.appendChild(item);
			});

			this.pages.push(wrapper);
		}

		this.initPages();

	}

	initPages() {
		document.querySelectorAll('.itemPage')[0].classList.add('show');

		this.initInterface();
	}

	initEvents() {
		document.querySelectorAll('#pager ul li a').forEach(link => {
			link.removeEventListener('click', this.loadPage.bind(this));
			link.addEventListener('click', this.loadPage.bind(this));
		});
	}

	initInterface() {
		var pager = document.querySelector("#pager");
		pager.innerHTML = '';

		if (pager !== null) {
			var wrapper = document.createElement('ul');

			//Previous link
			var firstLink = document.createElement('li');
			firstLink.classList.add('first');
			var link = this.createLink(document.location.pathname + '?page=previous', '&lt;');
			link.dataset.pagenumber = this.currentPage + 1;

			if (this.currentPage === 0) {
				firstLink.classList.add('disabled');
			}

			firstLink.appendChild(link);

			wrapper.appendChild(firstLink);

			//Pages
			for (var i = 0; i < this.pages.length; i++) {
				var pageLink = document.createElement('li');
				var querystring = "?page=" + (i + 1);

				if (i === 0) {
					pageLink.classList.add('active');
					querystring = "";
				}

				link = this.createLink(document.location.pathname + querystring, i + 1);
				link.dataset.pagenumber = i + 1;

				pageLink.appendChild(link);

				wrapper.appendChild(pageLink);
			}

			//Next link
			var nextLink = document.createElement('li');
			nextLink.classList.add('last');
			link = this.createLink(document.location.pathname + '?page=next', '&gt;');
			link.dataset.pagenumber = 2;
			nextLink.appendChild(link);
			wrapper.appendChild(nextLink);


			pager.appendChild(wrapper);
		}
		this.initEvents();
	}

	createLink(url, label) {
		var linkEl = document.createElement('a');
		linkEl.href = url;
		linkEl.innerHTML = label;
		return linkEl;
	}

	loadPage(e) {
		e.preventDefault();
		this.currentPage = e.target.dataset.pagenumber;
		this.loadNextPage();
		this.setActivePager();
	}

	setActivePager() {
		document.querySelectorAll('#pager ul li').forEach(pageItem => {
			pageItem.classList.remove('active');
			var linkItem = pageItem.querySelector('a');
			if (linkItem.dataset.pagenumber === this.currentPage && (!pageItem.classList.contains('first') && !pageItem.classList.contains('last'))) {
				pageItem.classList.add('active');
			}

			if (pageItem.classList.contains('first')) {
				var pageItemLink = pageItem.querySelector('a');
				if (parseInt(this.currentPage) === 1) {
					pageItem.classList.add('disabled');
					pageItemLink.dataset.pagenumber = 1;
				} else {
					pageItem.classList.remove('disabled');
					pageItemLink.dataset.pagenumber = parseInt(this.currentPage) - 1;
				}
			}

			if (pageItem.classList.contains('last')) {
				var pageItemLink = pageItem.querySelector('a');
				if (parseInt(this.currentPage) === this.pages.length) {
					pageItem.classList.add('disabled');
					pageItemLink.dataset.pagenumber = this.pages.length;
				} else {
					pageItem.classList.remove('disabled');
					pageItemLink.dataset.pagenumber = parseInt(this.currentPage) + 1;
				}
			}
		});
	}

	loadNextPage() {
		var pages = document.querySelectorAll('.itemPage');
		pages.forEach(pageItem => {
			pageItem.classList.remove('show');
		});

		pages[this.currentPage - 1].classList.add("show");
	}
}