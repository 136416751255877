class AVGController {
	constructor() {
		this.avgPermissionsCookie = Cookies.getJSON('AVGPermissions');
		this.cookieBar = document.querySelector('#cookieBar');
		this.LoadAVGScripts();
		this.CheckOpen();
		this.SetupButtonClicks();
	}

	get AcceptedFunctionalCookies() {
		if (this.avgPermissionsCookie === undefined) return false;

		return this.avgPermissionsCookie.Functional;
	}

	get AcceptedAnalyticsCookies() {
		if (this.avgPermissionsCookie === undefined) return true;

		return this.avgPermissionsCookie.Analytics;
	}

	get AcceptedTrackingCookies() {
		if (this.avgPermissionsCookie === undefined) return false;

		return this.avgPermissionsCookie.Tracking;
	}

	SetOrUpdatePermissionsCookie(functional, analytics, tracking) {
		this.avgPermissionsCookie = {
			Functional: functional,
			Analytics: analytics,
			Tracking: tracking
		};
		Cookies.set('AVGPermissions', this.avgPermissionsCookie, { expires: 99999 });
	}

	SetupButtonClicks() {
		document.querySelector('a.button.agreeCookies').addEventListener('click', this.AgreeCookies.bind(this));
		if (document.querySelector('form#cookieConfigure') !== null) {
			document.querySelector('form#cookieConfigure').addEventListener('submit', (event) => {
				event.preventDefault();
				this.SavePreferences();
			});
		}
	}

	LoadAVGScripts() {
		var head = document.querySelector('head');
		document.querySelectorAll('noscript[data-avg-type]').forEach(noscript => {
			switch (noscript.dataset.avgType.toLowerCase()) {
				case "analytics":
					if (this.AcceptedAnalyticsCookies) {
						let script = document.createElement('script');
						script.src = noscript.dataset.src;
						if (noscript.dataset.gakey !== null) {
							this.LoadGoogleAnalytics(noscript.dataset.gakey);
						}
						head.appendChild(script);
					}
					break;
				case "tracking":
					if (this.AcceptedTrackingCookies) {
						let script = document.createElement('script');
						script.src = noscript.dataset.src;
						head.appendChild(script);
					}
					break;
			}
		});
	}

	LoadGoogleAnalytics(gacode) {
		window.ga = window.ga || function () { (ga.q = ga.q || []).push(arguments); }; ga.l = +new Date;
		ga('create', gacode, 'auto');
		ga('set', 'forceSSL', true);
		ga('set', 'anonymizeIp', true);
		ga('send', 'pageview');

	}

	AgreeCookies() {
		this.SetOrUpdatePermissionsCookie(true, true, true);
		location.reload();
	}

	CheckOpen() {
		if (this.avgPermissionsCookie === undefined) {
			if (document.querySelector('#cookieBar a.button.goConfigure').href !== document.location.toString()) {
				this.cookieBar.classList.add('open');
			}
		}
	}

	SavePreferences() {
		this.SetOrUpdatePermissionsCookie(document.getElementById("Functional").checked, document.getElementById("Analytics").checked, document.getElementById("Tracking").checked);
		let saveConfirmation = document.querySelector('form#cookieConfigure .onSaveComplete');
		if (saveConfirmation !== null) {
			saveConfirmation.classList.add('success');
			setTimeout(function () {
				saveConfirmation.classList.remove('success');
			}, 1500);
		}
	}
}