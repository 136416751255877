/****
 * The App class should handle certain events, like Resize and Scroll, and pass that on to the controllers that need them
 * The App class should provide polyfills to make up for shortcomings older browsers like IE11 have
 * The App class should add itself to controllers as a context object; this way the underlying systems can always reach the app class
 ****/


class App {
	/**
	 * @param {boolean} [autoinit=true] Initialize on creating an instance
	 */
	constructor(autoinit = true) {
		this._resizeFunctions = [];
		this._resizeTimeout = null;
		this.resizeThrottle = false;

		this._scrollFunctions = [];
		this._scrollTimeout = null;
		this.scrollThrottle = false;

		if (autoinit) {
			this.init();
		}
	}
	
	init() {
		// Remove 'no-js' from the html tag, if it's there
		document.querySelector('html').classList.remove('no-js');

		this.setPolyfills();
		this.setEventListeners();

		this.watchHeight();
	}

	setPolyfills() {
		// .forEach() support for IE10/11
		if (NodeList.prototype.forEach === undefined) {
			NodeList.prototype.forEach = Array.prototype.forEach;
			HTMLElement.prototype.forEach = Array.prototype.forEach;
		}

		// .remove() support for IE10/11
		if (NodeList.prototype.remove === undefined) {
			NodeList.prototype.remove = function () {
				this.parentNode.removeChild(this);
			};

			HTMLElement.prototype.remove = function () {
				this.parentNode.removeChild(this);
			};
		}

		// .includes() support for IE10/11
		if (!Array.prototype.includes) {
			Object.defineProperty(Array.prototype, "includes", {
				enumerable: false,
				value: function (obj) {
					var newArr = this.filter(function (el) {
						return el === obj;
					});
					return newArr.length > 0;
				}
			});
		}

		// .closest() support for IE10/11
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.msMatchesSelector ||
				Element.prototype.webkitMatchesSelector;
		}

		// .closest() addition for DOMElements
		if (!Element.prototype.closest) {
			Element.prototype.closest = function (s) {
				var el = this;

				do {
					if (el.matches(s)) return el;
					el = el.parentElement || el.parentNode;
				} while (el !== null && el.nodeType === 1);
				return null;
			};
		}

		// Array.from() support for IE10/11
		if (!Array.from) {
			Array.from = function (object) {
				return [].slice.call(object);
			};
		}
	}

	setEventListeners() {
		// Adding passive as option to events helps with performance
		window.addEventListener('resize', this.resize.bind(this), {passive: true});
		document.addEventListener('scroll', this.scroll.bind(this), { passive: true });
	}

	/**
	 * @description Fires all registered events for a resize of the window
	 * */
	resize() {
		if (this._resizeTimeout !== null) {
			return;
		}

		const length = this._resizeFunctions.length;
		for (let i = 0; i < length; i++) {
			this._resizeFunctions[i]();
		}

		if (this.resizeThrottle) {
			this._resizeTimeout = setTimeout(() => {
				this._resizeTimeout = null;
			}, this.resizeThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a resize event
	 */
	registerResizeEvent(func) {
		this._resizeFunctions.push(func);
	}

	/**
	 * @param {function} func Function to be removed from the resize functions
	 */
	unregisterResizeEvent(func) {
		this._resizeFunctions.forEach((resizeFunc, index) => {
			if (resizeFunc === func) {
				this._resizeFunctions.split(index, 1);
			}
		});
	}

	/**
	 * @description Fires all registered events for a scroll event in the document
	 * */
	scroll() {
		if (this._scrollTimeout !== null) {
			return;
		}

		const length = this._scrollFunctions.length;
		for (let i = 0; i < length; i++) {
			this._scrollFunctions[i]();
		}

		if (this.scrollThrottle) {
			this._scrollTimeout = setTimeout(() => {
				this._scrollTimeout = null;
			}, this.scrollThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a scroll event
	 */
	registerScrollEvent(func) {
		this._scrollFunctions.push(func);
	}

	/**
	 * @param {function} func Function to be removed from the scroll functions
	 */
	unregisterScrollEvent(func) {
		this._scrollFunctions.forEach((scrollFunc, index) => {
			if (scrollFunc === func) {
				this._scrollFunctions.split(index, 1);
			}
		});
	}

	watchHeight() {
		const noteHeight = () => {
			const docEl = document.documentElement;
			docEl.style.setProperty('--fullHeight', `${window.innerHeight}px`);
		};
		window.addEventListener('resize', noteHeight);
		noteHeight();
	}

	/**
	 * @param {string} name Name the object will get in the app instance
	 * @param {object} object Object to be called
	 */
	addController(name, object) {
		this[name] = object;
		this[name].app = this;
	}
}