class CarouselController {
	constructor() {
		this.prevArrow = `
			<svg class="icon icon_carousel_arrow previous">
				<use xlink:href="#icon_carousel_arrow"></use>
			</svg>
		`;

		this.nextArrow = `
			<svg class="icon icon_carousel_arrow">
				<use xlink:href="#icon_carousel_arrow"></use>
			</svg>
		`;

		if (document.querySelectorAll('.carousel').length > 0) {
			this.initCarousel();
		}
	}

	initCarousel() {
		document.querySelectorAll('.carousel').forEach(carousel => {
			const dotContainer = carousel.parentNode.querySelector('.dots');
			$(carousel).slick({
				accessibility: true,
				prevArrow: this.prevArrow,
				nextArrow: this.nextArrow,
				dots: (dotContainer !== null),
				appendDots: dotContainer,
				enableTransform:true,
				infinite: false,
				responsive: [
					{
						breakpoint: 670,
						settings: {
							slidesToShow: 1,
							slidesToScroll:1
						}
					}]
			});
		});
	}
}