class ThemeFilterController {
	constructor(pagerController) {
		this.filterContainer = document.querySelector('.themeFilter.frontEnd .themes');
		this.selectedItems = [];

		this.initFilter();	
		this.pager = pagerController;
	}

	initFilter() {
		this.filterContainer.querySelectorAll('ul li input[type="checkbox"]').forEach(input => {
			if (input.checked) {
				this.selectedItems.push(input.value);
			}
		});

		this.initEvents();
	}

	initEvents() {
		this.filterContainer.querySelectorAll('ul li input[type="checkbox"]').forEach(input => {
			input.addEventListener('change', (event) => {
				event.preventDefault();
				if (input.checked) {
					this.selectedItems.push(input.value);
				} else {
					this.selectedItems.remove(input.value);
				}

				this.filterResults();
			});
		});
	}

	filterResults() {
		document.querySelectorAll('.listBlockView .block').forEach(listItem => {
			if (!this.selectedItems.includes(listItem.dataset.theme)) {
				listItem.classList.add('hide');
			} else {
				listItem.classList.remove('hide');
			}
		});

		this.pager.resetPages();
		this.pager.initPager();
	}

}

Array.prototype.remove = function () {
	var what, a = arguments, L = a.length, ax;
	while (L && this.length) {
		what = a[--L];
		while ((ax = this.indexOf(what)) !== -1) {
			this.splice(ax, 1);
		}
	}
	return this;
};