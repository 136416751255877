class CalendarViewController {
	constructor() {
		this.currentView = "";
		this.init();
		this.initMobileFilterView();
	}

	init() {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

		this.currentView = w > 799 ? "#mapview" : "#listview";
		if (document.querySelector('#mapview') === null) {
			this.currentView = "#listview";
		}
		this.toggleView();

		var toggleLinks = document.querySelectorAll("#viewTools a.toggleLink").forEach(toggleLink => {
			toggleLink.addEventListener('click', (event) => {
				event.preventDefault();
				var elId = toggleLink.attributes.getNamedItem('href').value;
				var el = document.querySelector(elId);
				this.currentView = elId;
				this.toggleView();
			});
		});
	}

	toggleView() {
		var views = document.querySelectorAll('.view').forEach(viewEl => {
			viewEl.classList.remove('show');
		});

		var currentView = document.querySelector(this.currentView);
		if (currentView !== null) {
			currentView.classList.add('show');
		}

		this.setActiveToggle();
	}

	setActiveToggle() {
		document.querySelectorAll("#viewTools a.toggleLink").forEach(toggleLink => {
			toggleLink.classList.remove('active');

			if (toggleLink.attributes.getNamedItem('href').value === this.currentView) {
				toggleLink.classList.add('active');
			}
		});
	}

	initMobileFilterView() {
		var mobileFilterToggle = document.querySelector('.mobileFilterToggle a');
		var mobileFilterBackground = document.querySelector('.mobileBackground');

		if (mobileFilterToggle !== null && mobileFilterBackground !== null) {
			mobileFilterToggle.addEventListener('click', (event) => {
				event.preventDefault();
				var calendarFilterPanel = document.querySelector('#calendarFilter');
				calendarFilterPanel.classList.add('show');
				mobileFilterBackground.classList.add('show');
			});
		}
		

		var mobileFilterSubmitButton = document.querySelector('.mobileButton a.submit');

		if (mobileFilterSubmitButton !== null) {
			mobileFilterSubmitButton.addEventListener('click', (event) => {
				event.preventDefault();
				var calendarFilterPanel = document.querySelector('#calendarFilter');
				calendarFilterPanel.classList.remove('show');
				mobileFilterBackground.classList.remove('show');
			});
		}
	}
}