class TimelineScrollController {
	constructor() {
		this.discoverIconsContainer = document.querySelector('section.discoverIcons');
		this.timelineOverviewContainer = document.querySelector('section.timelineOverview');
		console.log(this.discoverIconsContainer);
		console.log(this.timelineOverviewContainer);
		this.initEvents();	

	}

	initEvents() {
		if (this.discoverIconsContainer !== null && this.timelineOverviewContainer !== null) {
			this.discoverIconsContainer.querySelectorAll('.timelines a.timeline').forEach(timeline => {
				timeline.addEventListener('click', (event) => {
					
					if (timeline.dataset.timelineid !== null) {
						let timelineItem = this.timelineOverviewContainer.querySelector("#" + timeline.dataset.timelineid);
						if (timelineItem !== null) {
							event.preventDefault();
							window.scroll({
								top: (timelineItem.getBoundingClientRect().top + window.pageYOffset) - 150,
								left: 0,
								behavior: 'smooth'
							});
						}
					}
				});
			});
		}
	}
}